import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { BrandTypes, appStrings } from '../constants/enum';
import { useFormik } from "formik";
import * as yup from 'yup';
import axios from 'axios';

export function Registration() {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const initialValues = {
        name: '',
        phoneNumber: '',
        emailId: '',
        city: '',
        brandName: '',
        brandType: '',
        message: '',
    }
    const [brandType, setBrandType] = useState(BrandTypes.FREELANCER);
    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        phoneNumber: yup.string().required('Phone Number is required'),
        emailId: yup.string().email('Invalid email').required('Email is required'),
        city: yup.string().required('City is required'),
        brandName: yup.string().required('Brand Name is required'),
        message: yup.string().required('Please provide us some message'),
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            getRegistered(values);
        }
    });
    const getRegistered = async (payload: any) => {
        payload['brandType'] = brandType;
        setIsSubmitting(true);
        const payloadToAPI = {
            payload: [
                payload.name,
                payload.phoneNumber,
                payload.emailId,
                payload.brandType,
                payload.brandName,
                payload.city,
                payload.message
            ]
        }
        try {
            await axios.post('https://q04mcwk5cf.execute-api.us-east-1.amazonaws.com/default/ignitters_google_sheets_users_onboarding', payloadToAPI);
            setHasSubmitted(true);
        } catch (e) {
            setIsSubmitting(false);
            alert('Something went wrong. Sorry for the inconvenience. Please try again after sometime');
        }
    }
    const handleBrandTypeChange = (event: any) => {
        setBrandType(event.target.value);
    }

    return (
        <div className="bg-gradient-to-r from-gradientLeftColor to-gradientRightColor min-h-screen">
            {!hasSubmitted && <div className="flex items-center justify-center">
                <div className="container mx-auto px-4 py-8 ">
                    <div className="flex flex-wrap-reverse items-center justify-evenly">
                        <div className="w-full md:w-3/5 lg:w-2/5">
                            <p className="text-[2rem] tracking-[0.5rem] text-white font-black mb-[40px]">
                                {appStrings.registrationPageDescription}
                            </p>
                            <img src={require('../assets/img/registrationIllustration.png')} alt="Image Description" className="mx-auto max-h-full" />
                        </div>
                        <div className="w-full md:w-2/5 lg:w-3/5 mb-[20px]">
                            <div className='w-[75%] mx-auto'>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black' htmlFor="name">Name<sup>*</sup></label>
                                            <input className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border rounded ${formik.touched.name && formik.errors.name ? 'border border-errorRed' : ''}`} type="text" id="name" name="name" onBlur={formik.handleBlur} value={formik.values.name} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.name && formik.errors.name ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.name}</p> : null}
                                    </div>
                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black mb-[100px]' htmlFor="phoneNumber">Phone Number<sup>*</sup></label>
                                            <input type='number' className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border rounded ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'border border-errorRed' : ''}`} id="phoneNumber" name="phoneNumber" onBlur={formik.handleBlur} value={formik.values.phoneNumber} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.phoneNumber}</p> : null}
                                    </div>
                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black' htmlFor="emailId">Email ID<sup>*</sup></label>
                                            <input className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border rounded ${formik.touched.emailId && formik.errors.emailId ? 'border border-errorRed' : ''}`} type="text" id="emailId" name="emailId" onBlur={formik.handleBlur} value={formik.values.emailId} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.emailId && formik.errors.emailId ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.emailId}</p> : null}
                                    </div>
                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black' htmlFor="city">City<sup>*</sup></label>
                                            <input className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border rounded ${formik.touched.city && formik.errors.city ? 'border border-errorRed' : ''}`} id="city" name="city" onBlur={formik.handleBlur} value={formik.values.city} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.city && formik.errors.city ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.city}</p> : null}
                                    </div>
                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black' htmlFor="brandName">Brand Name<sup>*</sup></label>
                                            <input className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border rounded ${formik.touched.brandName && formik.errors.brandName ? 'border border-errorRed' : ''}`} type="text" id="brandName" name="brandName" onBlur={formik.handleBlur} value={formik.values.brandName} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.brandName && formik.errors.brandName ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.brandName}</p> : null}
                                    </div>

                                    <div className="my-[16px]">
                                        <div className="flex flex-col lg:flex-row my-[6px] gap-[10px]">
                                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                <input
                                                    className='relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300' id="radio1" name="radio1" type='radio' value={BrandTypes.COMPANY} checked={brandType === BrandTypes.COMPANY} onChange={handleBrandTypeChange} />
                                                <label
                                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-white font-black tracking-[2px]"
                                                    htmlFor="radioDefault02">
                                                    Company
                                                </label>
                                            </div>
                                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                <input
                                                    className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 rounded-full border-2 border-solid border-neutral-300" id="radio2" name="radio2" type='radio' value={BrandTypes.FREELANCER} checked={brandType === BrandTypes.FREELANCER} onChange={handleBrandTypeChange} />
                                                <p
                                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer text-white font-black tracking-[2px]"
                                                >
                                                    Freelancer
                                                </p>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="my-[16px]">
                                        <div className="my-[6px]">
                                            <label className='tracking-[2px] text-white font-black' htmlFor="message">Message<sup>*</sup></label>
                                            <textarea className={`tracking-[2px] text-white text-[12px] w-full bg-transparent p-3 border min-h-[120px] rounded ${formik.touched.message && formik.errors.message ? 'border border-errorRed' : ''}`} id="message" name="message" onBlur={formik.handleBlur} value={formik.values.message} onChange={formik.handleChange} />
                                        </div>
                                        {formik.touched.message && formik.errors.message ? <p className="tracking-[2px] text-errorRed text-[11px] font-semibold font-bricolage">{formik.errors.message}</p> : null}
                                    </div>
                                    <div>
                                        <button disabled={isSubmitting} type="submit" className='w-[100%] border border-white py-[12px] bg-white font-black rounded-[6px] text-center h-[50px]'>
                                            {!isSubmitting && <p>SUBMIT</p>}
                                            {isSubmitting && <img className='w-[25px] mx-auto' src={require('../assets/img/loading.gif')} />}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {hasSubmitted && <div className="flex items-center justify-center h-[75vh]">
                <div className="container mx-auto px-4 py-8 ">
                    <div>
                        <img src={require('../assets/img/thankyou.png')} alt="Image Description" className="mx-auto w-[50%]" />
                        <p className='text-center text-white font-black tracking-[2px] mt-[3%] text-[20px]'>Your Sumbission has been Received.</p>
                        <p className='text-center text-white font-black tracking-[2px] mt-[10px] text-[20px]'>we will be in touch with you soon!</p>
                    </div>
                </div>
            </div>}
        </div>
    )
}