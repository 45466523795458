import React from "react";
import { appStrings } from "../constants/enum";
import { useNavigate } from "react-router-dom";
import { Insights } from "./insights";

export function Home () {

    const navigate = useNavigate();

    return (
        <div className="bg-gradient-to-r from-gradientLeftColor to-gradientRightColor min-h-screen">
            <div className="flex items-center justify-center">
                <div className="container mx-auto px-4 py-8 ">
                    <div className="flex flex-wrap items-center justify-evenly">
                        <div className="w-full md:w-2/5 lg:w-3/5">
                            <p className="text-[1rem] md:text-[3rem] lg:text-[4rem] tracking-[0.5rem] text-white font-black">
                                {appStrings.landingPageDescription}
                            </p>
                        </div>
                        <div className="w-full md:w-3/5 lg:w-2/5">
                            <img src={require('../assets/img/landingIllustration1.png')} alt="Image Description" className="mx-auto max-h-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-4 py-4 flex flex-row items-center">
                <button className="px-[75px] py-[25px] border border-white" onClick={() => navigate('/register')}>
                    <p className="text-white font-black text-[1.2rem] font-Lastica">GET IGNITTED</p>
                </button>
            </div>
            <div className='h-[1px] bg-gray-400 w-[95%] mx-auto mt-[40px] mb-[20px]'></div>
            <Insights />
        </div>
    )
}