import { useEffect } from "react";
import data from "../assets/data/privacyPolicy.json";

export const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="bg-gradient-to-r from-gradientLeftColor to-gradientRightColor min-h-screen">
                <div className="container mx-auto px-4 py-8 ">
                    <div>
                        <p className="text-white font-black text-[32px] font-secondaryFontFamily">{data.heading}</p>
                    </div>
                    <div>
                        {
                            data?.content.map((section: any) => {
                                return (
                                    <div className="text-white font-secondaryFontFamily">
                                        <div className="my-[24px] text-[18px] font-black">
                                            <p>{section?.title}</p>
                                        </div>
                                        <div className="text-[16px]">
                                            {
                                                section?.content.split('\n').map((line: string, index: number) => (
                                                    <p key={index}>{line}</p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}