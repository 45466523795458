import React from 'react';
import { Home } from './pages/home';
import CustomRouter from './routes/customRouter';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Header } from './components/header';
import { Footer } from './components/footer';

function App() {
  return (
    <div>
      <Header />
      <CustomRouter/>
      <Footer />
    </div>
  );
}

export default App;
