import React from 'react';
import { useNavigate } from "react-router-dom";
import { ServicesProvided } from '../components/services';

export function Insights () {
    const navigate = useNavigate();

    const howIgnitteWorks = [
        {
            illustration: require('../assets/img/research.png'),
            heading: '1. Research',
            description: 'We understand our customers'
        },
        {
            illustration: require('../assets/img/strategize.png'),
            heading: '2. Strategize',
            description: 'Customize strategies to fit your needs'
        },
        {
            illustration: require('../assets/img/launch.png'),
            heading: '3. Launch',
            description: 'Generate ideas by turning results into actions'
        }
    ]

    const serviceProvided = [
        {
            illustration: require('../assets/img/digitalMarketing.png'),
            heading: 'Digital Marketing',
        },
        {
            illustration: require('../assets/img/socialMediaMarketing.png'),
            heading: 'Social Media Marketing',
        },
        {
            illustration: require('../assets/img/videoEditing.png'),
            heading: 'Video Editing',
        }
    ]

    return (
        // <div className="bg-gradient-to-r from-gradientLeftColor to-gradientRightColor min-h-screen">
        //     <div onClick={() => navigate('/home')} className="container mx-auto px-4 pt-[30px] flex flex-row items-center cursor-pointer">
        //             <img className="w-[3rem]" src={require('../assets/img/ignittersLogo.png')} alt="Image Description" />
        //             <p className="text-[1.5rem] text-white font-bold">ignitters</p>
        //     </div>
            

        // </div>
        <div>
            <div className="flex items-center justify-center">
                <div className="container mx-auto px-4 py-8 ">
                    <div>
                        <p className='tracking-[2px] text-center text-[26px] text-white font-black'>How Ignitte works?</p>
                    </div>
                    <div className='flex flex-row flex-wrap gap-5 mt-[50px] justify-around'>
                        {
                            howIgnitteWorks.map((strategy: any, index: number) => {
                                return (
                                    <div key={index} className='w-[230px]'>
                                        <div className='shadow-[0px_0px_30px_1px_rgba(255,255,255)] rounded-[50%]'>
                                            <div className='bg-[#18072B] h-[100%] p-[40px] rounded-[50%]'>
                                                <img className='w-[180px] mx-auto my-auto' src={strategy.illustration}/>
                                            </div>
                                        </div>
                                        <div className='mt-[30px]'>
                                            <p className='text-center text-textPurpleColor font-black tracking-[2px]'>{strategy.heading}</p>
                                        </div>
                                        <div className='mt-[6px]'>
                                            <p className='text-[18px] text-center font-secondaryFontFamily font-extrabold text-white'>{strategy.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className='h-[1px] bg-gray-400 w-[95%] mx-auto my-[20px]'></div>

            <div className="flex items-center justify-center">
                <div className="container mx-auto px-4 py-8 ">
                    <div>
                        <p className='tracking-[2px] text-center text-[26px] text-white font-black'>Services we provide</p>
                    </div>
                    <div>
                        {/* {
                            serviceProvided.map((service: any) => {
                                return (
                                    <div className=''>
                                        <div className=''>
                                            <div className='bg-[#18072B] h-[100%] p-[40px] w-[100%]'>
                                                <img className='w-[300px] h-[300px] mx-auto my-auto' src={service.illustration}/>
                                            </div>
                                        </div>
                                        <div className='mt-[30px]'>
                                            <p className='text-center text-textPurpleColor font-black tracking-[2px]'>{service.heading}</p>
                                        </div>
                                    </div>
                                )
                            })
                        } */}
                        <ServicesProvided />
                    </div>
                </div>
            </div>

            <div className='h-[1px] bg-gray-400 w-[95%] mx-auto my-[20px]'></div>

            <div className="flex items-center justify-center">
                <div className="container mx-auto px-4 py-8 ">
                    <div>
                        <p className='tracking-[2px] text-[26px] text-white font-black'>Let's talk business</p>
                    </div>
                    <div className='flex flex-row items-center justify-between flex-wrap'>
                        <div className='mb-[15px]'>
                            <div className='my-[30px]'>
                                <p className='text-textPurpleColor font-extrabold tracking-[2px] text-[20px]'>Phone</p>
                                <p className='font-secondaryFontFamily text-white mt-[10px] text-[18px]'>+91 812 238 4735</p>
                            </div>
                            <div className='my-[40px]'>
                                <p className='text-textPurpleColor font-extrabold tracking-[2px] text-[20px]'>Email</p>
                                <p className='font-secondaryFontFamily text-white mt-[10px] text-[20px]'>hello@ignitters.com</p>
                            </div>
                            <div className='flex flex-row flex-wrap gap-[20px] items-center'>
                                <a target='_blank' href='https://api.whatsapp.com/qr/BZLQMJITHOBUA1?autoload=1&app_absent=0'><img className='w-[30px]' src={require('../assets/img/whatsapp.png')} /></a>
                                <a target='_blank' href='https://www.facebook.com/profile.php?id=61551596744514&mibextid=LQQJ4d'><img className='w-[20px] h-[30px]' src={require('../assets/img/facebook.png')} /></a>
                                <a target='_blank' href='https://www.instagram.com/_ignitters_/?igshid=YTQwZjQ0NmI0OA%3D%3D'><img className='w-[30px]' src={require('../assets/img/instagram.png')} /></a>
                                <a target='_blank' href='https://www.linkedin.com/company/ignitters/about/'><img className='w-[30px]' src={require('../assets/img/linkedin.png')} /></a>
                                <a target='_blank' href='https://www.youtube.com/@Ignitters'><img className='w-[40px] h-[30px]' src={require('../assets/img/youtube.png')} /></a>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className='text-center text-textPurpleColor font-extrabold mb-[20px]'>About us</p>
                            </div>
                            <div>
                                <img className='w-[300px]' src={require('../assets/img/aboutUsQR.png')} />
                            </div>
                            <div className='flex flex-row items-center mt-[10px]'>
                                <img className='w-[18px]' src={require('../assets/img/scanner.png')} />
                                <p className='text-white font-secondaryFontFamily ml-[10px]'>scan this and get to know about us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}