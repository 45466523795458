import { useNavigate } from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-gradientLeftColor px-[30px]">
            <div className="flex flex-row items-center justify-between">
                <div>
                    <div onClick={() => navigate('/home')} className="container mx-auto py-4 flex flex-row items-center cursor-pointer">
                        <img className="w-[1rem] h-[1rem] mr-[4px]" src={require('../assets/img/ignittersLogo.png')} alt="Image Description" />
                        <p className="text-[0.8rem] text-white font-bold font-secondaryFontFamily"> <span className="text-[0.8rem]"> &copy; </span> 2024 Echo Edge Digital Solutions. All rights reserved.</p>
                    </div>
                </div>
                <div className="font-secondaryFontFamily text-white text-[0.8rem] font-black">
                    <p><span className="cursor-pointer" onClick={() => navigate('/termsAndConditions')}> Terms and Conditions </span> | <span className="cursor-pointer" onClick={() => navigate('/privacyPolicy')}> Privacy Policy </span> | <span className="cursor-pointer" onClick={() => navigate('/refundPolicy')}> Refund Policy </span></p>
                </div>
            </div>
        </div>
    )
}