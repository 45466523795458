import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home";
import { Registration } from "../pages/registration";
import { Insights } from "../pages/insights";
import { TermsAndConditions } from "../pages/termsAndConditions";
import { PrivacyPolicy } from "../pages/privacyPolicy";
import { RefundPolicy } from "../pages/refundPolicy";

export default function CustomRouter(){
    return(
        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/home" element={<Home/>}></Route>
            <Route path="/register" element={<Registration/>}></Route>
            <Route path="/insights" element={<Insights/>}></Route>
            <Route path="/termsAndConditions" element={<TermsAndConditions/>}></Route>
            <Route path="/privacyPolicy" element={<PrivacyPolicy/>}></Route>
            <Route path="/refundPolicy" element={<RefundPolicy/>}></Route>
        </Routes>
    )
}