import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/services.css";

export function ServicesProvided() {

    const settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    // dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const serviceProvided = [
        {
            illustration: require('../assets/img/digitalMarketing.png'),
            heading: 'Digital Marketing',
            keyPoints: [
                {
                    point: 'Elevate your brand with compelling storytelling.'
                },
                {
                    point: 'Harness the power of data analytics for informed decision-making.'
                },
                {
                    point: 'Foster customer loyalty through exceptional service.'
                },
                {
                    point: 'Expand your online presence to reach a wider audience.'
                },
            ]
        },
        {
            illustration: require('../assets/img/socialMediaMarketing.png'),
            heading: 'Social Media Marketing',
            keyPoints: [
                {
                    point: 'Elevate Your Business with Social Media Strategy!'
                },
                {
                    point: 'Amplify Your Reach with Social Media Engagement!'
                },
                {
                    point: 'Boost Brand Awareness Through Strategic Social Media Posts!'
                },
                {
                    point: 'Drive Conversions with Targeted Social Media Advertising!'
                },
            ]
        },
        {
            illustration: require('../assets/img/videoEditing.png'),
            heading: 'Video Editing',
            keyPoints: [
                {
                    point: 'Elevate Video Engagement with Our Editing Expertise!'
                },
                {
                    point: 'Enhance Your Storytelling with Our Video Editing Proficiency!'
                },
                {
                    point: 'Create Impactful Visuals with Our Editing Artistry!'
                },
                {
                    point: 'Boost Viewer Retention with Professional Video Editing!'
                },
            ]
        },
        {
            illustration: require('../assets/img/seo.png'),
            heading: 'Search Engine Optimization',
            keyPoints: [
                {
                    point: 'Optimize Your Online Presence with SEO Expert'
                },
                {
                    point: 'Maximize Visibility with Our SEO Mastery!'
                },
                {
                    point: 'Drive Organic Traffic with Strategic SEO Techniques!'
                },
                {
                    point: 'Outrank Competitors through Expert SEO Optimization!'
                },
            ]
        },
        {
            illustration: require('../assets/img/contentWriting.png'),
            heading: 'Content Writing',
            keyPoints: [
                {
                    point: 'Craft Unique & Engaging Content with Us!'
                },
                {
                    point: 'Capture Audiences with Compelling and Unique Storytelling!'
                },
                {
                    point: 'Fuel Engagement through Tailored Content Strategies!'
                },
                {
                    point: 'Leave a Lasting Impression with Our Exceptional Content Creation!'
                },
            ]
        },
        {
            illustration: require('../assets/img/influencerMarketing.png'),
            heading: 'Influencer Marketing',
            keyPoints: [
                {
                    point: 'Expand Your Brand\'s Reach with Influencer Marketing!'
                },
                {
                    point: 'Amplify Your Brand\'s Visibility through Influencer Collaborations!'
                },
                {
                    point: 'Leverage Trust and Authenticity with Influencer Marketing!'
                },
                {
                    point: 'Elevate Your Marketing Strategy with Influencer-Driven Growth!'
                },
            ]
        },
        {
            illustration: require('../assets/img/websiteDesigning.png'),
            heading: 'Website Designing',
            keyPoints: [
                {
                    point: 'Create User-Friendly Websites with Our Expertise!'
                },
                {
                    point: 'Enhance User Experiences with Our Website Design Prowess!'
                },
                {
                    point: 'Craft Visually Stunning and User-Friendly Websites with Us!'
                },
                {
                    point: 'Elevate Your Online Presence with Expert Website Creation!'
                },
            ]
        },
        {
            illustration: require('../assets/img/publicRelation.png'),
            heading: 'Public Relation',
            keyPoints: [
                {
                    point: 'Boost Public Relations with Strategic Branding!'
                },
                {
                    point: 'Enhance Reputation and Credibility with Strategic Branding!'
                },
                {
                    point: 'Strengthen Relationships with Stakeholders through PR Expertise!'
                },
                {
                    point: 'Boost Public Trust and Loyalty through Strategic PR Initiatives!'
                },
            ]
        },
        {
            illustration: require('../assets/img/politicalBranding.png'),
            heading: 'Political Branding',
            keyPoints: [
                {
                    point: 'Spread Your Political Party\'s Message to Everyone'
                },
                {
                    point: 'Amplify Your Political Party\'s Message with Comprehensive Outreach.'
                },
                {
                    point: 'Strengthen Relationships with Stakeholders through PR Expertise!'
                },
                {
                    point: 'Engage Diverse Audiences with Effective Message Dissemination.'
                },
            ]
        },
        {
            illustration: require('../assets/img/pitchDeck.png'),
            heading: 'Pitch Deck',
            keyPoints: [
                {
                    point: 'Make an Unforgettable Impression with a Winning Pitch Deck!'
                },
                {
                    point: 'Craft Compelling Visuals and Data for Your Business Pitch!'
                },
                {
                    point: 'Convey Your Vision and Strategy with Clarity and Impact!'
                },
                {
                    point: 'Elevate Your Pitch with Professionally Designed Deck Presentations.'
                },
            ]
        },
        {
            illustration: require('../assets/img/webDevelopment.png'),
            heading: 'Web Development',
            keyPoints: [
                {
                    point: 'Build Powerful and Secure Websites with Our Expertise'
                },
                {
                    point: 'Develop Dynamic and Scalable Websites with Our Prowess'
                },
                {
                    point: 'Craft Custom and Efficient Websites with Us'
                },
                {
                    point: 'Elevate Your Online Presence with Expert Website Development'
                },
            ]
        },
        {
            illustration: require('../assets/img/socialMediaManagement.png'),
            heading: 'Social Media Management',
            keyPoints: [
                {
                    point: 'Engage and grow your business with our social media marketing expertise.'
                },
                {
                    point: 'Boost brand awareness and drive traffic with our custom social media solutions.'
                },
                {
                    point: 'Elevate your brand to new heights with our social media marketing services.'
                },
                {
                    point: 'Connect with customers and generate leads with our social media marketing expertise.'
                },
            ]
        },
        {
            illustration: require('../assets/img/brandingService.png'),
            heading: 'Branding Service',
            keyPoints: [
                {
                    point: 'Craft a unique and memorable brand that resonates with your audience.'
                },
                {
                    point: 'Elevate your brand and stand out from the competition.'
                },
                {
                    point: 'Create a brand that inspires loyalty and drives growth.'
                },
                {
                    point: 'Partner with us to build a brand that you love and your customers trust.'
                },
            ]
        },
        {
            illustration: require('../assets/img/whatsappMarketing.png'),
            heading: 'Whatsapp Marketing',
            keyPoints: [
                {
                    point: 'Boost Your Business Reach through WhatsApp Marketing!'
                },
                {
                    point: 'Connect, Engage, and Convert with Our WhatsApp Strategies!'
                },
                {
                    point: 'Promote your products and services effectively and generate leads.'
                },
                {
                    point: 'Elevate Your Brand\'s Reach with Expert WhatsApp Campaigns!'
                },
            ]
        },
        {
            illustration: require('../assets/img/ecommerceWebsiteDevelopment.png'),
            heading: 'Ecommerce Website Development',
            keyPoints: [
                {
                    point: 'Build a powerful and profitable e-commerce website with our expertise.'
                },
                {
                    point: 'Craft a custom and user-friendly e-commerce website that drives sales'
                },
                {
                    point: 'Drive Sales, Delight Customers, and Expand Your Reach!'
                },
                {
                    point: 'Elevate your online presence and boost sales with our expert website development service'
                },
            ]
        },
        {
            illustration: require('../assets/img/logoAndPosterDesign.png'),
            heading: 'Logo and Poster Design',
            keyPoints: [
                {
                    point: 'Create Memorable Brand Identities with Our Logo Design Expertise!'
                },
                {
                    point: 'Captivate Audiences with Eye-Catching Posters Tailored Just for You!'
                },
                {
                    point: 'Elevate Your Visual Branding with Our Creative Design Solutions!'
                },
                {
                    point: 'Make Your Mark in Style with Professional Logo and Poster Design!'
                },
            ]
        },
        {
            illustration: require('../assets/img/introVideoCreation.png'),
            heading: 'Intro video creation',
            keyPoints: [
                {
                    point: 'Capture Attention from the Start with Stunning Intro Videos!'
                },
                {
                    point: 'Engage Your Audience with Compelling Visual Introductions!'
                },
                {
                    point: 'Elevate Your Brand\'s Storytelling with Expert Intro Video Creation!'
                },
                {
                    point: 'Leave a Lasting Impression with Our Professional Video Services!'
                },
            ]
        },
        {
            illustration: require('../assets/img/adVideoCreation.png'),
            heading: 'Ad video creation',
            keyPoints: [
                {
                    point: 'Showcase Your Brand in the Spotlight with Our Creative Video Ads!'
                },
                {
                    point: 'Craft high-quality ad videos that are optimized for all platforms and devices.'
                },
                {
                    point: 'Craft ad videos that tell your brand story and motivate viewers to take action.'
                },
                {
                    point: 'Transform Ideas into Dynamic Ads for Maximum Impact and ROI!'
                },
            ]
        },
    ]


    return (
        <div className="mt-[3%]">
            <Slider {...settings}>
                {serviceProvided.map((service: any, index: number) => {
                    return (
                        <div>
                            <div className="px-[20px]">
                                <div className="relative h-[400px] rounded-2xl text-white overflow-hidden cursor-pointer transition-all duration-700 card">
                                    <div className="absolute inset-0 w-full h-full flex justify-center items-center bg-[#18072B] transition-all duration-100 delay-200 z-20 hover:opacity-0">
                                        <img className='w-[300px] h-[300px] mx-auto my-auto' src={service.illustration} />
                                    </div>
                                    <div className="absolute inset-0 w-full h-full flex justify-center items-center bg-[#18072B] transition-all z-10 card-back">
                                    <div className="p-[10%]">
                                            {service.keyPoints.map((keyPoint: any) => {
                                                    return (
                                                        <div className="flex flex-row items-center">
                                                            <img className="w-[24px] h-[30px] mr-[10px]" src={require('../assets/img/bulletTick.png')} />
                                                            <p className="font-secondaryFontFamily text-[16px] my-[16px]">{keyPoint.point}</p>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-[30px]'>
                                    <p className='text-center text-textPurpleColor font-black tracking-[2px]'>{service.heading}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>;
        </div>
    )
}