import { useNavigate } from "react-router-dom";

export const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-gradient-to-r from-gradientLeftColor to-gradientRightColor">
            <div onClick={() => navigate('/home')} className="container mx-auto px-4 pt-[30px] flex flex-row items-center cursor-pointer">
                <img className="w-[3rem]" src={require('../assets/img/ignittersLogo.png')} alt="Image Description" />
                <p className="text-[1.5rem] text-white font-bold">ignitters</p>
            </div>
        </div>
    )
}